import type { FC } from "react";
import { IoChevronBack } from "react-icons/io5";

import { Box, Center, HStack, Icon } from "@chakra-ui/react";

import { useNavigate } from "@remix-run/react";

import { styleProps } from ".";
import AppBarWrapper from "./AppBarWrapper";

import { useLocaleNavigate } from "~/libs/i18n";

const DetailBar: FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ position: "fixed", top: 0, left: 2, zIndex: 20 }}
      {...styleProps}
    >
      <HStack w="full" py={4} alignItems="center">
        <Box
          as="button"
          boxSize={10}
          onClick={() => navigate(-1)}
          bgColor={"#FFF"}
          borderRadius={"full"}
        >
          <Center h="100%">
            <Icon as={IoChevronBack} boxSize={6} />
          </Center>
        </Box>
      </HStack>
    </Box>
  );
};

export default DetailBar;
