import { Capacitor } from "@capacitor/core";
import { motion } from "framer-motion";
import { Suspense, lazy, useMemo } from "react";



import { Box, Progress, type StyleProps, VStack } from "@chakra-ui/react";



import { Outlet, useLocation, useNavigation } from "@remix-run/react";



import Header from "~/components/Header";
import HotmobRenderer from "~/components/HotmobRenderer";
import TopNav from "~/components/TopNav";
import AppBar from "~/components/appBar";
import MenuDrawer from "~/components/mobile/MenuDrawer";


const MenuSearchDrawer = lazy(
  () => import("~/components/mobile/MenuSearchDrawer")
);

const Footer = lazy(() => import("~/components/Footer"));

export default () => {
  // let scrollTo: HTMLInputElement | null;
  const transition = useNavigation();
  const { pathname } = useLocation();

  const platform = Capacitor.getPlatform();

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     scrollTo = document?.getElementById(
  //       "viewWrapper"
  //     ) as HTMLInputElement | null;
  //     if (scrollTo) {
  //       document?.getElementById("viewWrapper")?.scrollTo({ top: 0 });
  //     }
  //   }
  // }, []);

  const mobileViewWrapperStyles = useMemo<StyleProps>(() => {
    if (platform === "web") return {};
    return {
      overflowY: "scroll",
      pos: "fixed",
      h: "100vh",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      pb: 20,
    };
  }, [platform]);

  return (
    <Box
      id="viewWrapper"
      className="mobileViewWrapper"
      {...mobileViewWrapperStyles}
    >
      <VStack alignItems="stretch" spacing={0} bgColor={"#FFF"}>
        <AppBar />
        <HotmobRenderer />
        <Box sx={{ position: "sticky", top: 0, zIndex: 20, bg: "#FFF" }}>
          <TopNav />
          <Header />
          {transition?.state === "loading" && (
            <Progress colorScheme="yellow" size="xs" isIndeterminate />
          )}
        </Box>
        <MenuDrawer />
        <Suspense>
          <MenuSearchDrawer />
        </Suspense>
        {/* <Suspense>
          <MenuPostDrawer
          />
        </Suspense> */}
        <motion.div
          key={pathname}
          initial="initial"
          animate="in"
          variants={{
            initial: {
              opacity: 0,
            },
            in: {
              opacity: 1,
            },
            out: {
              opacity: 0,
            },
          }}
          transition={{
            type: "tween",
            ease: "linear",
            duration: 0.3,
          }}
        >
          <Outlet />
        </motion.div>
        <Suspense>{platform === "web" && <Footer />}</Suspense>
      </VStack>
    </Box>
  );
};